import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'is-pre-efnol-header',
    templateUrl: './pre-efnol-header.component.html',
    styleUrls: ['./pre-efnol-header.component.scss'],
    standalone: false
})
export class PreEfnolHeaderComponent implements OnInit {
    userType: any;
    homeUrl: string;
    businessUrl: string;
    personalLoginUrl: string;
    insuranceHomeUrl: string;
    investingUrl: string;
    bankingUrl: string;
    resourcesHomeUrl: string;
    constructor (
        public router: Router,
        private sessionService: SessionService
    ) { }

    ngOnInit(): void {
        this.homeUrl = environment.NATIONWIDE.HOME;
        this.businessUrl = environment.NATIONWIDE.BUSINESS;
        this.personalLoginUrl = environment.NATIONWIDE.PERSONAL_LOGIN;
        this.insuranceHomeUrl = environment.NATIONWIDE.insuranceHome;
        this.investingUrl = environment.NATIONWIDE.investing;
        this.bankingUrl = environment.NATIONWIDE.banking;
        this.resourcesHomeUrl = environment.NATIONWIDE.resourcesHome;
    }

    claimRedirect(): void {
        this.sessionService.blockBrowserBack(false);
        this.userType = this.sessionService.getUserType();
        if (this.userType === 'registered') {
            this.router.navigate(['/fileclaim/info/claim-list-page']);
        } else {
            this.unauthRedirect();
        }
    }
    payBillRedirect(): void {
        window.location.href = environment.NATIONWIDE.payBill;
    }
    unauthRedirect(): void {
        window.open(environment.NATIONWIDE.AutoClaimsLandingPage, '_blank');
    }
    navigateToURL(url: string): void {
        window.location.href = url;
    }
    goToHome(): void {
        this.navigateToURL(this.homeUrl);
    }

    goToBusiness(): void {
        this.navigateToURL(this.businessUrl);
    }

    goToPersonalLogin(): void {
        this.navigateToURL(this.personalLoginUrl);
    }
}
