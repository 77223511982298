import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ErrorDataService } from '../shared/services/error-data.service';
import { LoggerService } from '../shared/services/logger.service';

@Component({
    selector: 'is-duplicate-error',
    templateUrl: 'duplicateError.component.html',
    standalone: false
})

export class DuplicateErrorComponent implements OnInit {
    public windowReplica = window;
    constructor (private LOGGER: LoggerService, private errorData: ErrorDataService) { }
    ngOnInit(): any {
        if (!this.windowReplica.frameElement) {
            this.LOGGER.error('Duplicate claim error occured');
        }
    }

    callNationwide(): void {
        this.windowReplica.location.href = environment.NATIONWIDE.HOME;
    }
}
