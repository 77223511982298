import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PersonalConstants } from 'app/common/personalConstants';
import { BoltFormHelper } from 'app/shared/services/bolt-form-helper';
import { LoggerService } from 'app/shared/services/logger.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Component({
    selector: 'is-test-page',
    templateUrl: './test-page.component.html',
    styleUrls: ['./test-page.component.scss'],
    standalone: false
})
export class TestPageComponent implements OnInit {
    iamBypassForm: UntypedFormGroup;
    showResults = false;
    showError = false;
    showWait = false;
    showServiceError = false;
    serviceBaseErrMsg = 'Something went wrong with the token call. Your guess is as good as mine as to what it was.';
    serviceErrMsg = '';
    links: any;
    historyCookieName = 'iamBypass';
    historyList = [];
    hasHistory = false;
    cookieExp = 14;
    iamBypassDTO = {
        'usernameText': '',
        'environment': '',
        'ccEnv': ''
    };
    environmentList: any;
    @ViewChild('environmentModal', { static: true }) environmentModal: ElementRef;

    constructor( private httpClient: HttpClient,
        private fh: BoltFormHelper,
        private cookieService: CookieService,
        private logger: LoggerService) { }

    ngOnInit(): void {
        this.preloadHistory();
        this.buildForm();
        this.iamBypassForm.valueChanges.subscribe((changes) => {
            this.iamBypassDTO.usernameText = this.iamBypassForm.get('usernameText').value;
            this.iamBypassDTO.environment = this.iamBypassForm.get('environment').value;
            this.iamBypassDTO.ccEnv = this.iamBypassForm.get('ccEnv').value;
        });
        this.getEnvironmentsFromGit();
    }

    buildForm(): void {
        this.iamBypassForm = this.fh.build({
            usernameText: [''],
            environment: [''],
            ccEnv: ['']
        });
    }

    openModal(): any {
        this.environmentModal.nativeElement.openModal();
    }

    preloadHistory(): void {
        if (this.cookieService.check(this.historyCookieName)) {
            const historyCookie = this.cookieService.get(this.historyCookieName);
            this.hasHistory = true;
            const historyCookieObj = JSON.parse(historyCookie);
            this.historyList = historyCookieObj;
        } else {
            this.logger.info('No history found');
        }
    }

    saveHistory(username: string): void {
        if (this.hasHistory) {
            if (!this.historyList.includes(username)) {
                this.historyList.push(username);
                this.cookieService.set(this.historyCookieName, JSON.stringify(this.historyList), {expires: this.cookieExp});
            }
        } else {
            this.historyList.push(username);
            this.cookieService.set(this.historyCookieName, JSON.stringify(this.historyList), {expires: this.cookieExp});
            this.hasHistory = true;
        }
    }

    deleteItem(item): boolean {
        const index = this.historyList.indexOf(item);
        if (index > -1) {
            this.historyList.splice(index, 1);
            this.cookieService.set(this.historyCookieName, JSON.stringify(this.historyList), {expires: this.cookieExp});
        }
        return false;
    }

    callEUA(username: string): Observable<any> {
        const formData = {
            grant_type: 'password',
            scope: 'openid',
            realm: 'member',
            auth_method: 'ping-sts',
            username: username,
            password: environment.iamBypass.acctInfo
        };
        const EUA2_TOKEN_FULL = environment.MONTY_AUTH_URL;
        const FULL_PARAMS = {
            'headers': this.getEUAServiceHeaders()
        };
        const formPayload = Object.keys(formData).map((key) => `${key}=${formData[<keyof typeof formData>key]}`).join('&');

        return this.httpClient.post(EUA2_TOKEN_FULL, formPayload, FULL_PARAMS);
    }

    getEUAServiceHeaders(): HttpHeaders {
        const BASIC_AUTH = environment.iamBypass.basicAuth;

        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        headers = headers.set(PersonalConstants.headerProps.AUTHORIZATION, `Basic ${BASIC_AUTH}`);

        return headers;
    }

    parseJWTBody(response): any {
        const jwtPayload = response.split('.')[1];
        const decodedJWT = atob(jwtPayload).toString();
        return JSON.parse(decodedJWT);
    }

    clear(): any {
        this.cookieService.delete(this.historyCookieName);
        this.cookieService.delete('claimsEnvNumber');
        this.cookieService.delete('claimsEnv');
        this.historyList = [];
        return false;
    }

    setEnv(): any {
        this.cookieService.set('claimsEnvNumber', this.iamBypassDTO.ccEnv, 14, '/', '.nwie.net');
        this.cookieService.set('claimsEnv', 'test', 14, '/', '.nwie.net');
    }

    continue(): void {
        this.showServiceError = false;
        this.showWait = true;
        this.showResults = false;
        const username = this.iamBypassDTO.usernameText;
        const env = this.iamBypassDTO.environment;
        const ccEnv = this.iamBypassDTO.ccEnv;
        let ccEnvAndServerNumber: any;
        if (username && env) {
            this.showError = false;
            const AUTHKEY = environment.iamBypass.authKey;

            ccEnvAndServerNumber = !ccEnv ? 'unset' : `test${ccEnv}`;
            if (ccEnv) this.setEnv();
            try {
                this.callEUA(username).subscribe({next: (response) => {
                    const {
                        access_token: accessToken,
                        expires_in: expiresIn,
                        id_token: idToken
                    } = response;

                    const parsedIdToken = this.parseJWTBody(response.id_token);
                    this.links = parsedIdToken.agreements
                    .filter(({ product_type: productType }) => productType.toLowerCase() !== 'billing')
                    .map(({ agreement_number: agreementNumber, product_type: productType }) => {
                        let host = '';
                        let address = '';
                        const agreementObj = {
                            env: env,
                            productType: productType,
                            agreementNumber: agreementNumber,
                            url: ''
                        };

                        if (productType === 'Auto' || productType === 'Personal Auto') {
                            host = environment.iamBypass.newApp.urls[env];
                            const indexPath = environment.iamBypass.newApp.indexPath[env];

                            address = `https://${host}${indexPath}` +
                                `?access_token=${accessToken}` +
                                `&expires_in=${expiresIn}` +
                                `&id_token=${idToken}` +
                                `&pkey=${agreementNumber}` +
                                `&encodedKey=${AUTHKEY}` +
                                `&IAM_BYPASS=true`;
                        } else {
                            host = environment.iamBypass.oldApp.urls[env];
                            const indexPath = environment.iamBypass.oldApp.indexPath[env];

                            address = `https://${host}${indexPath}` +
                                `#/redirect` +
                                `?access_token=${accessToken}` +
                                `&expires_in=${expiresIn}` +
                                `&id_token=${idToken}` +
                                `&IAM_BYPASS=true` +
                                `&pkey=${agreementNumber}` +
                                `&product_type=${productType}` +
                                `&cc_env=${ccEnvAndServerNumber}`;
                        }
                        agreementObj.url = address;
                        return agreementObj;
                    });
                    this.saveHistory(username);
                    this.showWait = false;
                    this.showResults = true;
                },
                error: (err) => {
                    this.showServiceError = true;
                    this.serviceErrMsg = err?.developerMessage;
                    this.showWait = false;
                }});
            } catch (err) {
                this.showServiceError = true;
                this.serviceErrMsg = this.serviceBaseErrMsg;
                this.showWait = false;
        }
        } else {
            this.showWait = false;
            this.showError = true;
        }
    }
    prefill(username: any): any {
        this.iamBypassForm.get('usernameText').setValue(username);
        return false;
    }

    getEnvironmentsFromGit(): any {
        const gitPackageJSON = 'https://github.nwie.net/api/v3/repos/Nationwide/dgs-internet-servicing-claims/contents/package.json?ref=update-pipeline-commands';
        const gitBranches = 'https://github.nwie.net/api/v3/repos/Nationwide/dgs-internet-servicing-claims/branches?per_page=100';
        fetch(gitBranches).then((branchResponse) => branchResponse.json())
            .then((branchData) => {
                fetch(gitPackageJSON).then((response) => response.json())
                    .then((data) => {
                        this.processGitResponse(data.content, branchData);
                    });
            });
    }

    processGitResponse(packageScripts: any, branchData: any): void {
        try {
            const scripts = JSON.parse(atob(packageScripts)).scripts;
            const keys = Object.keys(scripts); // get all keys from the JSON object
            const filteredKeys = keys.filter((key) => {
                const env = key.split(':');
                if (env[1] !== undefined) {
                    return ((env[1].startsWith('test-') || env[1].startsWith('dark-')));
                } else {
                    return false;
                }
            }); // filter keys that start with 'set-pipeline'
            const values = filteredKeys.map((key) => {
                let env = key.split(':')[1];
                env = env.startsWith('dark-') ? env.toUpperCase().replace('-', ' ') : env.split('-')[1].toUpperCase();
                let branch = scripts[key].split(' ')[9];
                branch = branch === undefined ? 'DEAD' : branch.split('=')[1];
                const latestCommit = this.findLatestCommit(branch, branchData);

                const envObj = {
                    'name': env,
                    'branch': branch,
                    'commitLink': latestCommit
                };
                return envObj;
            }); // get the values of the filtered keys
            this.environmentList = values;
        } catch (err) {
            this.logger.error('something went wrong', err);
        }
    }

    findLatestCommit(branch: any, branchData: Array<{ [key: string]: any}>): any {
        const gitCommit = 'https://github.nwie.net/Nationwide/dgs-internet-servicing-claims/commit/';
        const commit = branchData.find((obj) => {
            if (obj.name === branch) {
                return obj;
            } else {
                return false;
            }
        });

        return (commit !== undefined) ? gitCommit + commit.commit.sha : undefined;
    }
}
