import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimsOidcAuthService } from 'app/efnol-modernization/claims-oidc-auth/claims-oidc-auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'is-timeout-popup',
    templateUrl: './timeout-popup.component.html',
    styleUrls: ['./timeout-popup.component.scss'],
    standalone: false
})
export class TimeoutPopupComponent implements OnInit {
    @ViewChild('timeOutModalPopup', { static: true }) timeOutModalPopup: ElementRef;
    @Output() refreshSessionTime: EventEmitter<string> = new EventEmitter();
    userType: any;
    policyType: any;
    timeoutMin: Number;
    timeoutSec: Number;
    interval: any;
    constructor (
        public router: Router,
        public sessionService: SessionService,
        private authService: ClaimsOidcAuthService,
    ) {
        this.userType = this.sessionService.getUserType();
    }

    ngOnInit(): void { }

    closeModalPopup(): void {
        clearInterval(this.interval);
        this.interval = 0;
        this.timeOutModalPopup.nativeElement.closeModal();
        this.refreshSessionTime.emit();
    }
    openModalPopup(): void {
        this.startTimer(2, 53);
        this.timeOutModalPopup.nativeElement.openModal();
    }

    startTimer(minutes, seconds): void {
        let time = minutes * 60 + seconds;
        this.interval = setInterval(() => {
            if (time <= 0) {
                if (this.userType === 'registered') {
                    this.authService.logOffRegisteredUser();
                } else {
                    this.authService.logOffUnRegisteredUser();
                }
                this.navigateAway();
                clearInterval(this.interval);
                return;
            }
            this.timeoutMin = Math.floor(time / 60);
            this.timeoutSec = time % 60;
            time--;
        }, 1000);
    }

    sendExit(): void {
        this.sessionService.blockBrowserBack(false);
        if (this.userType === 'registered') {
            this.authService.logOffRegisteredUser();
        } else {
                this.authService.logOffUnRegisteredUser();
        }
        this.navigateAway();
        this.closeModalPopup();
    }

    navigateAway(): void {
        this.policyType = sessionStorage.getItem('policy-type') || sessionStorage.getItem('claimType');
        let toUrl = environment.NATIONWIDE.ClaimsLandingPage;
        switch (this.policyType) {
            case 'Auto':
                toUrl = environment.NATIONWIDE.AutoClaimsLandingPage;
                break;
            case 'Property':
            case 'Homeowners':
            case 'property':
                toUrl = environment.NATIONWIDE.PROPERTY_CLAIMS;
                break;
        }
        this.authService.clearSession();
        window.sessionStorage.setItem('dontShowPopup', 'true');
        this.navigateToUrl(toUrl);
    }

    navigateToUrl(url: string): void {
        window.open(url, '_self');
    }
}
