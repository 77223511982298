import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { PersonalConstants } from '../personalConstants';
interface ErrorBundle {
    message: string;
    errorCode: string;
    errorTypeCode: string;
}
@Component({
    selector: 'is-error-common',
    templateUrl: './error-common.component.html',
    styleUrls: ['./error-common.component.scss'],
    standalone: false
})

export class ErrorCommonComponent implements OnInit {
    noPolicy = false;
    error: ErrorBundle = {
        message: '', errorCode: '', errorTypeCode: ''
    };
    constructor (private route: ActivatedRoute) { }

    ngOnInit(): any {
        if (this.route && this.route.snapshot) {
            this.error.errorTypeCode = this.route.snapshot.queryParamMap.get('error');
        }
        console.log(this.error.errorTypeCode);
        switch (this.error.errorTypeCode) {
            case 'duplicateClaims':
                this.error.message = PersonalConstants.CLAIMS_ERROR_MSGS.DUPLICATE_CLAIM;
                break;
            case 'noPolicy':
                this.noPolicy = true;
                this.error.message = PersonalConstants.CLAIMS_ERROR_MSGS.BEFORE_POLICY_EFFECT_DATE;
                break;
            default:
                this.error.message = PersonalConstants.CLAIMS_ERROR_MSGS.WENT_WRONG;
                break;
        }
    }

    callNationwide(): void {
        window.location.href = environment.NATIONWIDE.HOME;
    }
}
