import {
    APP_BASE_HREF,
    CommonModule,
    Location,
    LocationStrategy,
    PathLocationStrategy,
    TitleCasePipe
} from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import '@nationwide-bolt/bundle-core';
import { BoltDirectivesModule } from '@nationwide-bolt/ng-directives';
import {
    FindRepairShopModule
} from '@nationwide/claims-component-library';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from './app.component';
import { BuildinfoComponent } from './buildinfo/buildinfo.component';
import { ClaimTrackerModule } from './claimsDashboard/claim-tracker/claim-tracker.module';
import { ClaimsDashboardModule } from './claimsDashboard/claims-dashboard.module';
import { EfnolHeaderComponent } from './common/efnol-header/efnol-header.component';
import { ErrorCommonComponent } from './common/error-common/error-common.component';
import { ExternalNavigateComponent } from './common/external-navigate/external-navigate.component';
import { HeaderProgressBarComponent } from './common/header-progress-bar/header-progress-bar.component';
import { PersonalConstants } from './common/personalConstants';
import { PreEfnolHeaderComponent } from './common/pre-efnol-header/pre-efnol-header.component';
import { TrackerHeaderComponent } from './common/tracker-header/tracker-header.component';
import { DuplicateErrorComponent } from './duplicateError/duplicateError.component';
import { ClaimsOidcAuthComponent } from './efnol-modernization/claims-oidc-auth/claims-oidc-auth.component';
import { ClaimsOidcAuthModule } from './efnol-modernization/claims-oidc-auth/claims-oidc-auth.module';
import { EfnolModernizationModule } from './efnol-modernization/efnol-modernization.module';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { ErrorPropComponent } from './errorProp/errorProp';
import { FindRepairShopComponent } from './findRepairShop/find-repair-shop.component';
import { PropertyClaimsModule } from './propertyclaims/property-claims.module';
import { AuthErrorInterceptor } from './route_guards/AuthErrorInterceptor';
import { AuthTokenGuard } from './route_guards/authToken.guard';
import { ProductionGuard } from './route_guards/production.guard';
import { AccessGuard, DirectAccessGuard, UnauthPropertyEntryGuard } from './route_guards/router-access.guard';
import { NwTestToolModule } from './shared/components/nw-test-tool/nw-test-tool.module';
import { HttperrorInterceptor } from './shared/interceptors/httperror.interceptor';
import { LoggerInterceptor } from './shared/interceptors/logger.interceptor';
import {
    AccessGuardServiceContact,
    AccessGuardServiceMemberProperty,
    AccessGuardServiceSummary
} from './shared/services/access-guard.service';
import { ClaimsChatDataService } from './shared/services/claims-chat-data.service';
import { CryptService } from './shared/services/crypt.service';
import { CTMService } from './shared/services/ctm.service';
import { HttpService } from './shared/services/http.service';
import { HttpHeaderService } from './shared/services/httpheader.service';
import { BackButtonGuard, NavigationGuardService } from './shared/services/navigation-guard.service';
import { SearchDataService } from './shared/services/search-data.service';
import { SharedServicesModule } from './shared/services/shared-services.module';
import { SoftfallService } from './shared/services/softfall.service';
import { SvgService } from './shared/services/svg.service';
import { windowRef, windowToken } from './shared/services/window';
import { SharedModule } from './shared/shared.module';
import { StubComponent } from './stub/stub.component';
import { TestPageComponent } from './testPage/test-page.component';
import { TimeoutPopupComponent } from './timeout-popup/timeout-popup.component';
import { TimeoutModalComponent } from './timeout/timeout.component';
const appRoutes: Routes = [
    { path: 'buildinfo', component: BuildinfoComponent },
    { path: 'error-500', component: Error500Component, data: { title: 'Error 500- Claims' } },
    { path: 'duplicateError', component: DuplicateErrorComponent, data: { title: 'DuplicateError- Claims' } },
    { path: 'error-common', component: ErrorCommonComponent, data: { title: 'Error- Claims' } },
    { path: 'external-navigate', component: ExternalNavigateComponent, data: { title: 'External Navigate Away' } },
    { path: 'error-Prop', component: ErrorPropComponent, data: { title: 'Error- Claims' } },
    { path: 'stubPar', canActivate: [ProductionGuard], component: StubComponent },
    { path: 'testpage', canActivate: [ProductionGuard], component: TestPageComponent },
    { path: 'find-repair-shop', data: { title: 'Find Repair Shop' }, component: FindRepairShopComponent },
    { path: 'error-404', component: Error404Component, data: { title: 'Error 404- Claims' } },
    { path: 'authenticate', redirectTo: 'call-back', pathMatch: 'full'},
    { path: '', component: ErrorCommonComponent, data: { title: 'Error- Claims' } },
    { path: 'call-back', component: ClaimsOidcAuthComponent, data: { title: 'Authorization call back' }},
    { path: '**', component: Error404Component }
];
@NgModule({ bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [TimeoutPopupComponent],
    declarations: [
        AppComponent,
        Error404Component,
        Error500Component,
        DuplicateErrorComponent,
        ErrorPropComponent,
        StubComponent,
        ErrorCommonComponent,
        TimeoutModalComponent,
        EfnolHeaderComponent,
        PreEfnolHeaderComponent,
        HeaderProgressBarComponent,
        TrackerHeaderComponent,
        TimeoutPopupComponent,
        ExternalNavigateComponent,
        BuildinfoComponent,
        TestPageComponent,
        FindRepairShopComponent
    ], imports: [BrowserModule,
        ClaimsDashboardModule,
        CommonModule,
        EfnolModernizationModule,
        SharedModule,
        SharedServicesModule,
        RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' }),
        PropertyClaimsModule,
        BrowserAnimationsModule,
        ClaimsOidcAuthModule,
        NwTestToolModule,
        BoltDirectivesModule,
        ClaimTrackerModule,
        FormsModule,
        InternetServicingAngularComponentsModule,
        ReactiveFormsModule,
        FindRepairShopModule], providers: [
        AuthErrorInterceptor,
        AuthTokenGuard,
        Location,
        TitleCasePipe,
        ProductionGuard,
        HttpService,
        CryptService,
        SoftfallService,
        HttpHeaderService,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: 'config', useValue: environment },
        { provide: 'constants', useValue: PersonalConstants },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttperrorInterceptor,
            multi: true
        },
        { provide: 'servicingCommonConfig', useValue: environment },
        AccessGuard,
        UnauthPropertyEntryGuard,
        DirectAccessGuard,
        SvgService,
        BackButtonGuard,
        NavigationGuardService,
        AccessGuardServiceContact,
        AccessGuardServiceMemberProperty,
        AccessGuardServiceSummary,
        SearchDataService,
        CookieService,
        CTMService,
        DeviceDetectorService,
        { provide: windowToken, useValue: windowRef },
        {
            provide: JwtHelperService,
            useFactory: (): any => new JwtHelperService()
        },
        ClaimsChatDataService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
