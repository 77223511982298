import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { PersonalConstants } from 'app/common/personalConstants';
import { ROUTING } from 'app/common/routeConstants';
import { ClaimsOidcHelperService } from 'app/shared/services/claims-oidc-helper.service';
import { EfnolCacheService } from 'app/shared/services/efnol-cache.service';
import { LoggerService } from 'app/shared/services/logger.service';
import { SessionService } from 'app/shared/services/session.service';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ClaimsOidcAuthService } from './claims-oidc-auth.service';

@Component({
    selector: 'is-claims-oidc-auth',
    templateUrl: './claims-oidc-auth.component.html',
    styleUrls: ['./claims-oidc-auth.component.scss'],
    standalone: false
})
export class ClaimsOidcAuthComponent implements OnInit, OnDestroy {
    isAuthenticated = false;
    activePolicies = [];
    checkauthSubscription$: Subscription;
    getIdToken$: Subscription;
    getExpandUserIdToken$: Subscription;
    getUnregIdToken$: Subscription;
    authEvent$: Subscription;

    coveragesExcludeRoutes = [
        PersonalConstants.LOGIN_ENTRY_POINTS.EXTERNAL_APP_ENTRY,
        PersonalConstants.LOGIN_ENTRY_POINTS.RETURNING_LINK,
        PersonalConstants.LOGIN_ENTRY_POINTS.DUPLICATE_RETURNING_MEMBER,
        PersonalConstants.LOGIN_ENTRY_POINTS.CLAIMS_TRACKER,
        PersonalConstants.LOGIN_ENTRY_POINTS.INCIDENT_DETAILS,
        PersonalConstants.LOGIN_ENTRY_POINTS.PAYMENT_OPTIONS,
        PersonalConstants.LOGIN_ENTRY_POINTS.FILE_UPLOAD,
        PersonalConstants.LOGIN_ENTRY_POINTS.MFA_LOGIN
    ];

    constructor (
        private router: Router,
        private authService: ClaimsOidcAuthService,
        private logger: LoggerService,
        private oidcEventService: PublicEventsService,
        private efnolCache: EfnolCacheService,
        private sessionService: SessionService,
        private oidcHelper: ClaimsOidcHelperService
    ) { }

    async ngOnInit(): Promise<void> {
        const userType = this.sessionService.getUserType();
        const loginState = this.sessionService.getLoginState();
        await this.checkAuthenticationResponse().then((loggedInConfig) => {
            switch (loggedInConfig) {
                case PersonalConstants.LOGINTYPE.REGISTERED:
                    this.handleRegisteredLogin(loginState);
                    break;
                case PersonalConstants.LOGINTYPE.UNREGISTERED:
                    this.handleUnregisteredLogin(userType, loginState);
                    break;
                case PersonalConstants.LOGINTYPE.UNREGISTEREDEXPANDUSER:
                    this.handleExpandUserLogin(userType, loginState);
                    break;
                case 'refreshSession':
                    break;
            }
        }).catch((err) => {
            this.handleLoginFailures(userType, err);
        });
    }

    handleExpandUserLogin(userType: string, loginState: any): void {
        this.getExpandUserIdToken$ = this.authService.getIdToken().subscribe((jsonWebToken) => {
            this.sessionService.setSessionItem(PersonalConstants.MFA_LOGIN_COMPONENT.POLICYNUMBER, jsonWebToken.policyNumber);
            sessionStorage.setItem(PersonalConstants.MFA_LOGIN_COMPONENT.CLAIMNUMBER, jsonWebToken.claimNumber);
            sessionStorage.setItem(PersonalConstants.MFA_LOGIN_COMPONENT.CONTACTID, jsonWebToken.contactID);
            this.oidcHelper.processDeviceAnalyze().then((response) => {
                if (response?.status === 'CHALLENGE') {
                    this.logger.info('Expand User: Login Component - challenge or MFA is required', response);
                    this.authService.setAuthStatus(true, false);
                    this.router.navigate([`/${ROUTING.DASHBOARD.BASE_PATH}/${ROUTING.DASHBOARD.CHILD_PATH_MFA}/${ROUTING.MFA.CONTACT_MFA}`]);
                } else if (response?.access_token) {
                    this.logger.info('Expand User: Login Component - challenge or MFA is not required received access_token', response);
                    this.authService.setAccessToken(response.access_token);
                    sessionStorage.setItem('ExpandUserLogin', JSON.stringify(response));
                    this.sessionService.setSessionItem('MFAToken', response);
                    const mfaUserType = this.authService.getMFAUserType();
                    this.sessionService.setSessionItem('userType', mfaUserType);
                    sessionStorage.setItem('registrationType', mfaUserType);
                    this.authService.setAuthStatus(true, false);
                    this.router.navigate([`/${ROUTING.AUTO.BASE_PATH}/${ROUTING.AUTO.CHILD_PATH_TRACKER}`]);
                } else {
                    this.logger.info('Expand User: Login Component - deviceAnalyze API call failed on click of continue', response);
                    this.router.navigate([PersonalConstants.URLS.errorCommon]);
                }
            }, (error) => {
                this.logger.info('Expand User: Login Component - deviceAnalyze API call failed on click of continue', error);
                this.router.navigate([PersonalConstants.URLS.errorCommon]);
            });
        });
    }

    ngOnDestroy(): void {
        if (this.checkauthSubscription$) this.checkauthSubscription$.unsubscribe();
        if (this.getExpandUserIdToken$) this.getExpandUserIdToken$.unsubscribe();
        if (this.getIdToken$) this.getIdToken$.unsubscribe();
        if (this.getUnregIdToken$) this.getUnregIdToken$.unsubscribe();
        if (this.authEvent$) this.authEvent$.unsubscribe();
    }

    handleIdentificationReturning(usertype?: string): void {
        const claimIdFromUrl = this.oidcHelper.getClaimNuberForReturningRegisteredUser(usertype);
        this.router.navigate([`/fileclaim/auto/duplicate-claim-returning-link/${claimIdFromUrl}`]);
    }

    handleLoginFailures(userType: string, error?: any, type?: string): void {
        if (userType.indexOf('unregistered') > -1) {
            if (this.sessionService.getSessionItemDecrypted('fromMFA') === 'true') {
                const mfaUserType = this.authService.getMFAUserType();
                const queryParamsMFA = this.oidcHelper.getMFALoginErrorURL(mfaUserType);
                this.router.navigate([`/${ROUTING.DASHBOARD.BASE_PATH}/${ROUTING.DASHBOARD.CHILD_PATH_MFA}/${ROUTING.MFA.LOGIN_MFA}`], { queryParams: queryParamsMFA });
            } else if (type === 'property') {
                    this.navigateToUrl(environment.NATIONWIDE.PROPERTY_CLAIMS);
                } else {
                    this.router.navigate(['/fileclaim/info/identification'], { queryParams: { error: 'accessDenied' } });
                }
        } else {
            this.logger.error('Login Failure', error);
            this.router.navigate([PersonalConstants.URLS.errorCommon]);
        }
    }

    handleUnregisteredLogin(userType: string, loginState: string): void {
        this.getUnregIdToken$ = this.authService.getIdToken().subscribe((jsonWebToken) => {
            const lineOfBusiness = this.authService.getLineOfBusiness(jsonWebToken) || '';
            const productType = this.authService.getProductType(jsonWebToken) || '';
            if (lineOfBusiness === 'Property' || productType === 'Property') {
                switch (loginState) {
                    case PersonalConstants.LOGIN_ENTRY_POINTS.EXTERNAL_APP_ENTRY:
                        // set login state and add  condition after property efnol implementation
                        this.router.navigate(['/propertyclaims/first-notice-of-loss']);
                        break;
                    case PersonalConstants.LOGIN_ENTRY_POINTS.INCIDENT_DETAILS:
                    case PersonalConstants.LOGIN_ENTRY_POINTS.PAYMENT_OPTIONS:
                    case PersonalConstants.LOGIN_ENTRY_POINTS.FILE_UPLOAD:
                        this.handleTrackerNavigation(loginState);
                        break;
                    default: this.handleLoginFailures(userType, null, 'property');
                        break;
                }
            } else {
                switch (loginState) {
                    case PersonalConstants.LOGIN_ENTRY_POINTS.RETURNING_LINK:
                        this.handleIdentificationReturning(userType);
                        break;
                    case PersonalConstants.LOGIN_ENTRY_POINTS.IDENTIFICATION_PAGE:
                        if (userType.indexOf('non-member') >= 0 || userType === PersonalConstants.REGISTRATION_TYPE.UNREGISTEREDMEMBERSUPPORT) {
                            this.logger.info('', '',
                                {Navigation: 'Claim-Modernization: Navigating to contactDetailsUnauth Page'});
                            this.router.navigate(['/fileclaim/info/contact-details-unauth']);
                        } else {
                            this.logger.info('', '',
                                {Navigation: 'Claim-Modernization: Navigating to whenDidAccidentOccur Page'});
                            this.router.navigate(['/fileclaim/info/when-did-accident-occur']);
                        }
                        break;
                    case PersonalConstants.LOGIN_ENTRY_POINTS.INCIDENT_DETAILS:
                    case PersonalConstants.LOGIN_ENTRY_POINTS.PAYMENT_OPTIONS:
                    case PersonalConstants.LOGIN_ENTRY_POINTS.FILE_UPLOAD:
                        this.handleTrackerNavigation(loginState);
                        break;
                    default: this.handleLoginFailures(userType);
                        break;
                }
            }
        });
    }

    handleRegisteredLogin(loginState: string): void {
        this.sessionService.setSessionItem(PersonalConstants.FILE_CLAIM_COMPONENT.USERTYPE, 'registered');
        this.getIdToken$ = this.authService.getIdToken().subscribe((jsonWebToken) => {
            if (this.coveragesExcludeRoutes.indexOf(loginState) === -1) {
                if (jsonWebToken.agreements.length > 0) {
                    this.oidcHelper.getActivePolicies(jsonWebToken.agreements).then((response) => {
                        this.activePolicies = response.activePolicyCoverages;
                        if (response.activePolicyCount >= 0) {
                            this.navigation(loginState);
                        } else {
                            this.handleLoginFailures('registered');
                        }
                    });
                }
            } else {
                this.navigation(loginState);
            }
        });
    }

    navigation(loginState: string): void {
        switch (loginState) {
            case PersonalConstants.LOGIN_ENTRY_POINTS.IDENTIFICATION_PAGE:
                if (this.activePolicies.length > 1) {
                    window.sessionStorage.setItem(PersonalConstants.FILE_CLAIM_COMPONENT.CLAIMTYPE, 'Auto');
                    this.logger.info('User has multiple policy', { numberOfPolicies: this.activePolicies.length, policyNumber: this.activePolicies },
                        {Navigation: 'Claim-Modernization: Navigating to multipleActivePolicies Page'});
                    this.router.navigate(['/fileclaim/info/multiple-active-policies']);
                } else if (this.activePolicies.length === 1) {
                    window.sessionStorage.setItem(PersonalConstants.FILE_CLAIM_COMPONENT.CLAIMTYPE, 'Auto');
                    this.efnolCache.setInitialFnolModel();
                    const policyNumber = this.activePolicies[0].agreement_number;
                    this.sessionService.setSessionItem(PersonalConstants.FILE_CLAIM_COMPONENT.POLICYNUMBER,
                        policyNumber);
                    this.logger.info('', '',
                        {Navigation: 'Claim-Modernization: Navigating to whenDidAccidentOccur Page'});
                    this.router.navigate(['/fileclaim/info/when-did-accident-occur']);
                } else {
                    this.logger.info('', '',
                        {Navigation: 'Claim-Modernization: Navigating to noPolicyFound Page'});
                    this.router.navigate(['/fileclaim/info/no-policy-found']);
                }
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.EXTERNAL_APP_ENTRY:
                this.router.navigate(['/fileclaim/info/claim-list-page']);


                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.PROPERTY_FIRST_NOTICE_OF_LOSS:
                    this.router.navigate(['/propertyclaims/first-notice-of-loss']);
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.DUPLICATE_RETURNING_MEMBER:
                const policy_number = this.sessionService.getSessionItemDecrypted(PersonalConstants.FILE_CLAIM_COMPONENT.RETURNINGPOLICYNUMBER);
                this.sessionService.setSessionItem(PersonalConstants.FILE_CLAIM_COMPONENT.POLICYNUMBER, policy_number);
                this.router.navigate(['/fileclaim/info/claim-list-page']);
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.RETURNING_LINK:
                this.handleIdentificationReturning();
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.CLAIMS_TRACKER:
            case PersonalConstants.LOGIN_ENTRY_POINTS.INCIDENT_DETAILS:
            case PersonalConstants.LOGIN_ENTRY_POINTS.PAYMENT_OPTIONS:
            case PersonalConstants.LOGIN_ENTRY_POINTS.FILE_UPLOAD:
                this.handleTrackerNavigation(loginState);
                break;
        }
    }

    handleTrackerNavigation(loginState?: string): void {
        const queryParamsFound = JSON.parse(sessionStorage.getItem('queryParams'));
        switch (loginState) {
            case PersonalConstants.LOGIN_ENTRY_POINTS.CLAIMS_TRACKER:
                this.router.navigate([`/${ROUTING.AUTO.BASE_PATH}/${ROUTING.AUTO.CHILD_PATH_TRACKER}`], { queryParams: queryParamsFound });
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.INCIDENT_DETAILS:
                this.router.navigate([`/${ROUTING.DASHBOARD.BASE_PATH}/${ROUTING.DASHBOARD.CHILD_PATH_INCIDENT}`], { queryParams: queryParamsFound });
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.PAYMENT_OPTIONS:
                this.router.navigate([`/${ROUTING.ACH.BASE_PATH}/${ROUTING.ACH.CHILD_PATH}`], { queryParams: queryParamsFound });
                break;
            case PersonalConstants.LOGIN_ENTRY_POINTS.FILE_UPLOAD:
                this.router.navigate([`/${ROUTING.UPLOAD.BASE_PATH}`], { queryParams: queryParamsFound });
                break;
        }
    }

    navigateToUrl(url: string): void {
        window.open(url, '_self');
    }

    async checkAuthenticationResponse(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.authEvent$ = this.oidcEventService
                .registerForEvents()
                .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
                .subscribe((eventResult) => {
                    let configId;
                    if (this.sessionService.getUserType().indexOf('unregistered') > -1) {
                        configId = this.sessionService.getSessionItemDecrypted('fromMFA') === 'true' ? PersonalConstants.LOGINTYPE.UNREGISTEREDEXPANDUSER : PersonalConstants.LOGINTYPE.UNREGISTERED;
                    } else {
                        configId = PersonalConstants.LOGINTYPE.REGISTERED;
                    }
                    if (eventResult.value?.isAuthenticated) {
                        if (eventResult.value?.isRenewProcess) {
                            resolve('refreshSession');
                        } else {
                            this.checkauthSubscription$ = this.authService.checkOidcAuthStatus(configId).subscribe((checAuthResult) => {
                                this.authService.setAccessToken(checAuthResult.accessToken);
                                this.authService.setIdToken(this.authService.parseJwt(checAuthResult.idToken));
                                this.authService.setAuthStatus(checAuthResult.isAuthenticated, configId === PersonalConstants.LOGINTYPE.REGISTERED);
                                this.authService.setExpiration(PersonalConstants.OIDCTOKENTIME.TOKENEXPIRYTIME);
                                resolve(configId);
                            });
                        }
                    } else {
                        reject(new Error('Access Denied'));
                    }
                });

            setTimeout(() => {
                reject(new Error('login timeout'));
            }, 20000);
        });
    }
}
