import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTING } from 'app/common/routeConstants';
import { ClaimsTrackerLoadGuard, IncidentDetailsLoadGuard, PaymentOptionsLoadGuard } from 'app/route_guards/claims-tracker.guard';

const DASHBOARD = ROUTING.DASHBOARD;
const AUTO = ROUTING.AUTO;
const ACH = ROUTING.ACH;
const UPLOAD = ROUTING.UPLOAD;
const ClaimsDashboardRoutes: Routes = [
    {
        path: `${DASHBOARD.BASE_PATH}`,
        children: [
            {
                path: `${DASHBOARD.CHILD_PATH_INCIDENT}`,
                loadChildren: (): any =>
                    import('./incident-details/incident-details.module').then(
                        (m) => m.IncidentDetailsModule
                    ),
                canLoad: [IncidentDetailsLoadGuard]
            },
            {
                path: `${DASHBOARD.CHILD_PATH_TRACKER}`,
                loadChildren: (): any =>
                    import('./old-tracker/claim-tracker.module').then(
                        (m) => m.ClaimTrackerModule
                    ),
                canLoad: [ClaimsTrackerLoadGuard]

            },
            {
                path: `${DASHBOARD.CHILD_PATH_MFA}`,
                loadChildren: (): any => import('./mfa/mfa.module').then((m) => m.MfaModule)
            }
        ]
    },
    {
        path: `${ACH.BASE_PATH}`,
        children: [
            {
                path: `${ACH.CHILD_PATH}`,
                loadChildren: (): any =>
                    import('./claimspayment/claims-payment.module').then(
                        (m) => m.ClaimsPaymentModule
                    ),
                canLoad: [PaymentOptionsLoadGuard]
            }
        ]
    },
    {
        path: `${UPLOAD.BASE_PATH}`,
        children: [
            {
                path: ``,
                loadChildren: (): any =>
                    import('./file-upload/file-upload.module').then(
                        (m) => m.FileUploadModule
                    )
            }
        ]
    },
    {
        path: `${AUTO.BASE_PATH}`,
        children: [
            {
                path: `${AUTO.CHILD_PATH_TRACKER}`,
                loadChildren: (): any =>
                    import('./claim-tracker/claim-tracker.module').then(
                        (m) => m.ClaimTrackerModule
                    ),
                canLoad: [ClaimsTrackerLoadGuard]
            }
        ]
    }
];
@NgModule({ schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [],
    exports: [RouterModule], imports: [RouterModule.forChild(ClaimsDashboardRoutes)], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ClaimsDashboardModule { }
